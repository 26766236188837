import { Swiper, SwiperSlide } from "swiper/react";
import { MdNoDrinks } from "react-icons/md";
import { FaLeaf } from "react-icons/fa";
import { TbTestPipe } from "react-icons/tb";
import { GiSparkles } from "react-icons/gi";

// Import Swiper styles
import "swiper/css";

const Slide = ({ title, content, IconComponent }) => {
  return (
    <div className="rounded-lg overflow-hidden">
      <div className="relative">
        <div className="p-4 grid grid-cols-12">
          <h3 className="text-lg font-bold mb-2 col-span-3 max-sm:my-auto">
            <IconComponent className="bg-slate-100 p-6 rounded-full shadow-sm text-[80px] text-stone-600" />
          </h3>
          <div className="text-gray-600 mb-4 col-span-9 grid grid-cols-1 w-full max-sm:text-3xl">
            <h2 className="text-[20px] font-normal normal-font uppercase text-stone-600">{title}</h2>
            <h2 className="text-[12px] font-normal normal-font text-stone-600">{content}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default () => {
  return (
    <Swiper
      spaceBetween={20}
      slidesPerView={1}
      breakpoints={{
        640: { slidesPerView: 2 },
        768: { slidesPerView: 3 },
      }}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
      className="h-fit justify-center align-middle"
    >
      <SwiperSlide className="my-auto h-[160px!important] border-2 border-primary">
        <Slide
          title="Visible Results"
          content='The visible, transformative results customers can expect to see, using descriptors like "radiant" and "noticeable" to set high expectations.'
          IconComponent={GiSparkles}
        />
      </SwiperSlide>
      <SwiperSlide className="my-auto h-[160px!important] border-2 border-primary">
        <Slide
          title="Gentle Efficacy"
          content="The gentle, non-irritating efficacy of the product, backed by dermatologist approval, to reassure users it is safe and effective."
          IconComponent={FaLeaf}
        />
      </SwiperSlide>
      <SwiperSlide className="my-auto h-[160px!important] border-2 border-primary">
        <Slide
          title="Advanced Formulation"
          content="The advanced, cutting-edge nature of the product formulation, emphasizing the clinically-tested and innovative ingredients."
          IconComponent={TbTestPipe}
        />
      </SwiperSlide>
      
    </Swiper>
  );
};
