import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { FaEye, FaCartPlus, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ProductContext } from "../ProductContext";
import orangeImage from "../utilies/01_list.png";
import backgroundImage from "./bg_about.jpg";
import { createWhatsAppLink } from "../utilies/WhatsappMessage";

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const { basket, setBasket } = useContext(ProductContext);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetch("https://m0hamady.pythonanywhere.com/api/products/");
      if (!response.ok) {
        throw new Error("Failed to fetch products");
      }
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % products.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [products]);

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  const addToBasket = (product) => {
    setBasket((prevBasket) => [...prevBasket, product]);
  };

  return (
    <div
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="w-full"
    >
      <Helmet>
        <title>Sophia Cosmetics - Products</title>
        <meta name="description" content="Discover our range of Sophia Cosmetics products, designed to enhance your natural beauty. Shop now for skincare, makeup, and more." />
        <meta name="keywords" content="Sophia Cosmetics, skincare, makeup, beauty products" />
      </Helmet>

      <div className="w-full flex flex-row max-sm:flex-col gap-2 p-4 max-h-[300ox] h-[300px] bg-gradient-to-r from-[#eae9d8] to-[#f7f5f1]">
        <div className="flex w-1/2 items-end max-sm:w-full max-sm:items-start max-sm:h-1/2">
          <img
            src={orangeImage}
            alt="Sophia Cosmetics"
            className="h-[150%] max-w-lg object-cover object-center w-full max-sm:h-[100%]"
          />
        </div>
        <div className="w-1/2 flex flex-row flex-wrap max-sm:w-full">
          <h1 className="uppercase text-[80px] tracking-wide font-bold text-primary title-font max-sm:text-[37px]">
            Sophia Cosmetics
          </h1>
          <p className="normal-font text-[#335B74] font-semibold tracking-wide text-xl max-sm:text-lg">
            Empower individuals to embrace their unique beauty with confidence and grace.
          </p>
        </div>
      </div>
      <ProductList products={products} addToBasket={addToBasket} />
    </div>
  );
};

ProductsPage.propTypes = {
  // No props needed for this component
};

const Slider = ({ products, currentSlide, handleSlideChange }) => (
  <div className="relative w-full h-fit overflow-hidden rounded mx-2">
    <img
      src={products[currentSlide]?.image}
      alt={products[currentSlide]?.name}
      className="w-full max-w-lg h-[500px] max-h-[500px] py-5 shadow-3xl rounded object-cover lg:mx-auto max-sm:max-w-[350px] max-sm:mx-auto object-bottom"
    />
    <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
      {products.map((_, index) => (
        <button
          key={index}
          className={`w-3 h-3 rounded-full transition-colors duration-300 ${
            index === currentSlide ? "bg-white" : "bg-gray-400 hover:bg-gray-500"
          }`}
          onClick={() => handleSlideChange(index)}
        />
      ))}
    </div>
  </div>
);

Slider.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
      offer_price: PropTypes.string,
      image: PropTypes.string.isRequired,
      inventory_count: PropTypes.number.isRequired,
      is_active: PropTypes.bool.isRequired,
    })
  ).isRequired,
  currentSlide: PropTypes.number.isRequired,
  handleSlideChange: PropTypes.func.isRequired,
};

const ProductList = ({ products, addToBasket }) => (
  <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 py-4">
    {products.map((product) => (
      <div
        key={product.id}
        className="bg-primary shadow-md rounded-lg overflow-hidden relative mx-2"
      >
        <Link to={`/products/${product.id}`} aria-label={`View details of ${product.name}`}>
          <div className="absolute -right-12 -bottom-12 w-24 h-24 rounded-full shadow-3xl bg-secondary"></div>
          <FaEye className="absolute right-3 text-primary bottom-3 z-30 shadow-3xl" />
        </Link>

        <img
          src={product.image}
          alt={product.name}
          className="w-full h-[400px] object-cover object-bottom max-sm:max-w-[350px] max-sm:mx-auto"
        />
        <div className="p-4 max-sm:mx-auto max-sm:max-w-[350px]">
          <h3 className="text-lg text-gray-100 font-normal normal-font">
            {product.name}
          </h3>
          {product.offer_price ? (
            <div className="flex gap-6">
              <p className="text-red-800 line-through mb-2">
                {parseFloat(product.price).toFixed(2)} LE
              </p>
              <p className="text-blue-800 font-semibold mb-2">
                {parseFloat(product.offer_price).toFixed(2)} LE
              </p>
            </div>
          ) : (
            <p className="text-blue-500 font-semibold mb-2">
              {parseFloat(product.price).toFixed(2)} LE
            </p>
          )}
          <a
            href={createWhatsAppLink(
              `I would like to ask about  ${product.name} with price ${parseFloat(product.effective_price).toFixed(2)} LE from your website.`
            )}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-[#335B74] duration-300  relative hover:bg-[#5EB6C4] text-white px-4 py-2 pr-8 rounded-md mr-2 mt-5"
            aria-label={`Contact about ${product.name} on WhatsApp`}
          >
            WhatsApp <FaWhatsapp className="absolute right-1 top-3 text-lg " />
          </a>
          <button
            onClick={() => addToBasket(product)}
            className="bg-[#335B74] duration-300 hover:bg-[#5EB6C4] text-white px-4 py-2 rounded-md text-lg h-[37px] translate-y-1"
            aria-label={`Add ${product.name} to basket`}
          >
            <FaCartPlus className="mt-[1px]" />
          </button>
        </div>
      </div>
    ))}
  </div>
);

ProductList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
      offer_price: PropTypes.string,
      effective_price: PropTypes.string,
      image: PropTypes.string.isRequired,
      inventory_count: PropTypes.number.isRequired,
      is_active: PropTypes.bool.isRequired,
    })
  ).isRequired,
  addToBasket: PropTypes.func.isRequired,
};

export default ProductsPage;
