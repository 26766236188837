import React from 'react';
import { CardCarouselImage } from '../../assets/images/indedx';

const HeroSection = () => {
  return (
    <div className="relative w-full h-[600px] md:h-[749px]">
      {/* Background layers */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 " />
        <img
          className="absolute w-full h-full   object-cover "
          src={CardCarouselImage}
          alt="Background"
        />
      </div>

      {/* Content Box */}
      <div className="absolute top-[100px] max-md:top-[153px] max-sm:top-[400px] lg:left-[55vw] left-1/2 transform -translate-x-1/2 lg:transform-none w-[90%] sm:w-[80%] md:w-[515.27px] h-auto bg-[#d9d8d9]/40 px-4 py-6 sm:px-6 sm:py-8 md:px-[34px] md:py-[63px] rounded-[10px] backdrop-blur-[10.20px] flex flex-col justify-start gap-4 sm:gap-6">
        {/* Title */}
        <div className="text-center md:text-left text-[#1e1e1e] text-2xl sm:text-3xl md:text-[52px] font-bold leading-tight md:leading-[65px] title-font">
        Cosmetics that every one loves
        </div>

        {/* Description */}
        <div className="text-center md:text-left text-[#1e1e1e] text-sm sm:text-base md:text-lg font-semibold leading-relaxed md:leading-[39px] normal-focus-font">
        Sophia, founded in 2013, has become a trailblazer in the skincare industry, revolutionizing beauty routines worldwide.
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
