import React, { useState, useEffect } from 'react';

export default function PrivacyAndPolicy() {
    const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      const elementPosition = document.getElementById('privacy-and-policy').offsetTop;
      const windowHeight = window.innerHeight;

      if (scrollPosition + windowHeight >= elementPosition) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div id="privacy-and-policy" className="px-4 py-12 md:px-8 lg:px-12">
      <h1 className={`text-3xl font-bold mb-6 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-100`}>Privacy Policy</h1>

      <h2 className={`text-2xl font-bold mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-200`}>Information We Collect</h2>
      <p className={`mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-300`}>
        At Sophia Cosmetics, we collect various types of information from our customers, including:
      </p>
      <ul className="list-disc pl-6 space-y-2">
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-400`}>Contact information (name, email address, shipping address, etc.)</li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-500`}>Payment information (credit card details, billing address, etc.)</li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-600`}>Product purchase history and browsing activity on our website</li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-700`}>Demographic information (age, gender, interests, etc.)</li>
      </ul>

      <h2 className={`text-2xl font-bold mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-800`}>How We Use Your Information</h2>
      <p className={`mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-900`}>
        We use the information we collect to:
      </p>
      <ul className="list-disc pl-6 space-y-2">
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-1000`}>Process and fulfill your orders</li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-1100`}>Improve our products and services</li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-1200`}>Personalize your shopping experience</li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-1300`}>Communicate with you about your orders and special offers</li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-1400`}>Comply with legal and regulatory requirements</li>
      </ul>

      <h2 className={`text-2xl font-bold mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-1500`}>Sharing Your Information</h2>
      <p className={`mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-1600`}>
        We may share your information with:
      </p>
      <ul className="list-disc pl-6 space-y-2">
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-1700`}>Third-party service providers that help us with order fulfillment, customer support, and marketing</li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-1800`}>Law enforcement or government agencies if required by law</li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-1900`}>In the event of a merger, acquisition, or sale of assets</li>
      </ul>

      <h2 className={`text-2xl font-bold mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-2000`}>Protecting Your Information</h2>
      <p className={`mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-2100`}>
        We take reasonable measures to protect your information from unauthorized access, use, or disclosure. This includes using encryption and other security technologies.
      </p>

      <h2 className={`text-2xl font-bold mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-2200`}>Your Rights</h2>
      <p className={`mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-2300`}>
        You have the right to access, correct, or delete your personal information, as well as opt-out of certain marketing communications. Please contact us if you would like to exercise these rights.
      </p>

      <h2 className={`text-2xl font-bold mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-2400`}>Contact Us</h2>
      <p className={`mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-2500`}>
        If you have any questions or concerns about our privacy practices, please contact us at:
      </p>
      <ul className="list-disc pl-6 space-y-2">
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-2600`}>Email: privacy@sophiacosmetics.com</li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-2700`}>Phone: +20 2 2792 3456</li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-2800`}>Address: 12 El Qasr El Ainy St., Cairo, Egypt</li>
      </ul>
    </div>
  )
}