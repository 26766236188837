import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Grid,
  CardMedia,
  Avatar,
} from "@mui/material";
import {
  FaCheckCircle,
  FaHourglassHalf,
  FaHourglassStart,
  FaTimesCircle,
} from "react-icons/fa";
import image from "../utilies/wild-deer-nature.jpg"; // Ensure this path is correct

export default function ExactOrder() {
  const { orderId } = useParams(); // Extract orderId from the URL
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const token = localStorage.getItem("token"); // Retrieve token from local storage

        const response = await axios.get(
          `https://m0hamady.pythonanywhere.com/api/orders/${orderId}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include token in the request header
            },
          }
        );

        setOrder(response.data);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch order data");
        setLoading(false);
      }
    };

    fetchOrder();
  }, [orderId]); // Depend on orderId to refetch if it changes

  if (loading) return <CircularProgress />;
  if (error) return <p className="text-red-500">{error}</p>;

  if (!order) return <p>No order data available</p>;

  return (
    <div className="h-[150vh] px-0 relative">
      <div className="absolute top-0 left-0 w-full h-[500px] -mt-40">
        <img
          src={image}
          alt="Order Banner"
          className="w-full h-full object-cover shadow-lg"
        />
      </div>
      <div className="relative flex justify-center z-10">
        <div className="bg-white w-full max-w-4xl rounded-2xl shadow-xl p-6 mt-60">
          <div className="text-xl text-gray-900 mb-4">
            <Typography variant="h4">Order ID: #{order.id}</Typography>
            <Typography variant="h6" className="mt-2">
              <strong>Client Name:</strong> {order.client_name}
            </Typography>
            <Typography variant="h6" className="mt-2">
              <strong>Total Amount:</strong> EGP {order.total_amount} ({order.payment_method})
            </Typography>
            <Typography variant="h6" className="mt-2">
              <strong>Created At:</strong> {new Date(order.created_at).toLocaleString()}
            </Typography>
            <Typography variant="h6" className="mt-2">
              <strong>Payment Status:</strong> {order.payment_status}
            </Typography>
            <div className="mt-2">
              <strong>Status:</strong>
              <div className="flex items-center mt-2">
                {order.received ? (
                  <span className="text-green-600 flex items-center">
                    <FaCheckCircle className="mr-2" /> Received
                  </span>
                ) : order.delivered ? (
                  <span className="text-blue-600 flex items-center">
                    <FaHourglassHalf className="mr-2" /> In Transit
                  </span>
                ) : order.packaged ? (
                  <span className="text-yellow-600 flex items-center">
                    <FaHourglassStart className="mr-2" /> Packaged
                  </span>
                ) : (
                  <span className="text-red-600 flex items-center">
                    <FaTimesCircle className="mr-2" /> Not Packaged
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex -space-x-1 overflow-hidden mt-4">
            {order.items.length > 0 ? (
              order.items.map((item) => (
                <div
                  key={item.id}
                  className={`inline-block h-16 w-16 rounded-full ring-2 border-2 ring-white ${
                    item.is_ready ? "border-green-600" : "border-red-600"
                  }`}
                >
                  {item.product_image ? (
                    <img
                      src={item.product_image}
                      alt={item.product_name}
                      className="object-cover w-full h-full rounded-full"
                    />
                  ) : (
                    <Avatar sx={{ width: 56, height: 56 }} className="bg-gray-300">
                      {item.product_name.charAt(0)}
                    </Avatar>
                  )}
                </div>
              ))
            ) : (
              <p>No items found</p>
            )}
          </div>
        </div>
      </div>

     
    </div>
  );
}
