import React, { useState, useEffect, useContext } from "react";
import { FaStar, FaRegStar } from "react-icons/fa";
import { BsCart4 } from "react-icons/bs";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import { ProductContext } from "../ProductContext";
import { Helmet } from "react-helmet-async";

const ProductDetail = () => {
  const { id } = useParams(); // Get id from URL params
  const [product, setProduct] = useState(null);
  const [rating, setRating] = useState(4.5);
  const [hover, setHover] = useState(null);
  const { addToBasket } = useContext(ProductContext); // Use context

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          `https://m0hamady.pythonanywhere.com/api/products/${id}/`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch product");
        }
        const data = await response.json();
        setProduct(data);
      } catch (error) {
        console.error("Error fetching product:", error);
        // Handle error state or retry logic here
      }
    };

    fetchProduct();
  }, [id]); // Fetch data whenever id changes

  const handleRating = (value) => {
    setRating(value);
  };

  const handleHover = (value) => {
    setHover(value);
  };

  const handleAddToBasket = () => {
    if (product) {
      addToBasket(product);
    }
  };

  if (!product) {
    return <p>Loading...</p>; // Handle loading state while fetching data
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="grid grid-cols-1 md:grid-cols-2 gap-8 p-8 normal-font"
    >
      <Helmet>
        <html lang="en" />
        <title>
          {product.name} - Buy Now at ${parseFloat(product.price).toFixed(2)}
        </title>
        <meta name="description" content={product.description} />
        <meta property="og:title" content={product.name} />
        <meta property="og:description" content={product.description} />
        <meta property="og:image" content={product.image} />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: product.name,
            image: product.image,
            description: product.description,
            sku: product.id,
            offers: {
              "@type": "Offer",
              priceCurrency: "EGP",
              price: parseFloat(product.price).toFixed(2),
              availability: "https://schema.org/InStock",
              url: window.location.href,
            },
          })}
        </script>
      </Helmet>
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <img
          src={product.image}
          alt="Product Image"
          className="w-fit mx-auto h-auto max-h-[60vh] rounded-lg object-contain shadow-3xl"
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <h1 className="text-3xl font-bold mb-4 title-font uppercase tracking-wide number-font ">
          {product.name}
        </h1>
        <div className="flex items-center mb-4">
          {[1, 2, 3, 4, 5].map((value) => (
            <span
              key={value}
              className="text-yellow-500 cursor-pointer"
              onMouseEnter={() => handleHover(value)}
              onMouseLeave={() => handleHover(null)}
              onClick={() => handleRating(value)}
            >
              {value <= (hover || rating) ? <FaStar /> : <FaRegStar />}
            </span>
          ))}
          <span className="text-gray-500 ml-2">({rating} / 5)</span>
        </div>
        <p className="text-gray-700 mb-4">{product.description}</p>
        <div className="flex items-center mb-4">
          <span className="text-2xl font-bold mr-4 normal-font uppercase tracking-wide number-font">
            {" "}
            ${parseFloat(product.price).toFixed(2)}
          </span>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleAddToBasket}
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center"
          >
            <BsCart4 className="mr-2" />
            Add to Cart
          </motion.button>
        </div>
        <h2 className="text-xl font-bold mb-4 title-font uppercase tracking-wider">
          Key Features:
        </h2>
        <motion.ul
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="list-disc pl-6 text-gray-700"
        >
          {product.key_features.map((feature) => (
            <li key={feature.id}>{feature.feature_text}</li>
          ))}
        </motion.ul>
      </motion.div>
    </motion.div>
  );
};

export default ProductDetail;
