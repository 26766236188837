import React, { useEffect, useRef } from 'react';

const BirdsAnimation = ({ children }) => {
  const vantaRef = useRef(null);

  useEffect(() => {
    if (!vantaRef.current) {
      // Load the VANTA.BIRDS library from the CDN
      const script = document.createElement('script');
      script.src = 'https://cdnjs.cloudflare.com/ajax/libs/three.js/r128/three.min.js';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        const anotherScript = document.createElement('script');
        anotherScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/vanta/0.5.24/vanta.birds.min.js';
        anotherScript.async = true;
        document.body.appendChild(anotherScript);

        anotherScript.onload = () => {
          // Now the VANTA object is available
          vantaRef.current = window.VANTA.BIRDS({
            el: '#birds-animation',
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.0,
            minWidth: 200.0,
            scale: 1.0,
            scaleMobile: 1.0,
            birdSize: 2.0,
            speedLimit: 4.0,
            separation: 81.0,
            alignment: 66.0,
            cohesion: 86.0,
            quantity: 2.0,
            backgroundAlpha: 0,
          });
        };
      };
    }

    return () => {
      if (vantaRef.current) {
        vantaRef.current.destroy();
      }
    };
  }, []);

  return (
    <div
      id="birds-animation"
      style={{
        width: '100%',
        height: '300%',
        position: 'relative',
        overflow: 'hidden',
        background:'transparent'
      }}
    >
      {children}
    </div>
  );
};

export default BirdsAnimation;