import React, { useState, useEffect } from 'react';

export default function Terms_Condition() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className=" rounded-lg shadow-lg p-12 animate-fade-in">
      <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>
      <p className={`mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out`}>
        Welcome to Sophia Cosmetics, Egypt's leading provider of premium skin care products. By purchasing or using any of our products through our website, you agree to be bound by these Terms and Conditions.
      </p>
      <h2 className={`text-2xl font-bold mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-100`}>Product Information</h2>
      <ul className="list-disc pl-6 space-y-2">
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-200`}>
          All Sophia products are manufactured in our state-of-the-art facility at El Qasr El Ainy Hospital in Cairo, Egypt.
        </li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-300`}>
          Our products are made with the highest quality, clinically-tested ingredients and are certified by the relevant authorities in Egypt.
        </li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-400`}>
          We use advanced formulas to provide effective skin lightening, brightening, and nourishing benefits, with a focus on sensitive areas.
        </li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-500`}>
          Customers should carefully review the product descriptions, ingredients lists, and usage instructions before purchasing.
        </li>
      </ul>
      <h2 className={`text-2xl font-bold mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-600`}>Returns and Refunds</h2>
      <ul className="list-disc pl-6 space-y-2">
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-700`}>
        Customers have 30 days from the date of delivery to request a return or refund.
        </li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-800`}>
        Products must be unused and in their original condition to be eligible for a refund.
        </li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-900`}>
        Shipping costs are non-refundable. Customer is responsible for return shipping fees.
        </li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-900`}>
        We may require proof of purchase for all returns and refunds.
        </li>
      </ul>
      <h2 className={`text-2xl font-bold mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-600`}>Ordering and Payment</h2>
      <ul className="list-disc pl-6 space-y-2">
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-700`}>
          Customers can order Sophia products exclusively through our website.
        </li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-800`}>
          All orders must be paid for at the time of purchase using accepted online payment methods.
        </li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-900`}>
          We reserve the right to cancel or refuse any order for any reason.
        </li>
      </ul>
      <h2 className={`text-2xl font-bold mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-600`}>Product Safety and Efficacy</h2>
      <ul className="list-disc pl-6 space-y-2">
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-700`}>
        All Sophia products are rigorously tested to ensure safety and efficacy.
        </li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-800`}>
        However, individual results may vary. Discontinue use if any adverse reactions occur.
        </li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-900`}>
        Pregnant women, nursing mothers, and those with pre-existing medical conditions should consult a doctor before using our products.
        </li>
      </ul>
      <h2 className={`text-2xl font-bold mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-600`}>Privacy and Data Protection</h2>
      <ul className="list-disc pl-6 space-y-2">
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-700`}>
        We are committed to protecting the privacy of our customers.
        </li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-800`}>
        Personal information collected is used only for order processing and customer service.
        </li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-900`}>
        Our full Privacy Policy is available on our website.
        </li>
      </ul>
      <h2 className={`text-2xl font-bold mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-600`}>Intellectual Property</h2>
      <ul className="list-disc pl-6 space-y-2">
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-700`}>
        Sophia Cosmetics owns all intellectual property rights in our products, packaging, and marketing materials.
        </li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-800`}>
        Unauthorized use or reproduction of our trademarks, copyrights, or other IP is strictly prohibited.
        </li>

      </ul>
      <h2 className={`text-2xl font-bold mb-4 ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-600`}>Governing Law      </h2>
      <ul className="list-disc pl-6 space-y-2">
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-700`}>
        These Terms and Conditions are governed by the laws of Egypt.
        </li>
        <li className={`${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-700 ease-in-out delay-800`}>
        Any disputes will be resolved through binding arbitration in Cairo.
        </li>

      </ul>
      {/* Add more sections as needed */}
    </div>
  );
}