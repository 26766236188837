import React, { useContext, useEffect, useState } from 'react';
import { ErrorContext } from '../ErrorContext';

const ErrorContainer = () => {
  const { errors, removeError } = useContext(ErrorContext);
  const [currentErrors, setCurrentErrors] = useState([]);

  useEffect(() => {
    setCurrentErrors(errors.map((error, index) => ({
      ...error,
      id: index,
      removing: false, // Track removing state
    })));
  }, [errors]);

  useEffect(() => {
    const timers = currentErrors.map((error, index) =>
      setTimeout(() => {
        setCurrentErrors((prevErrors) =>
          prevErrors.map((e) =>
            e.id === index ? { ...e, removing: true } : e
          )
        );
        setTimeout(() => removeError(index), 300); // Remove after animation
      }, 3000)
    );

    return () => {
      timers.forEach(clearTimeout);
    };
  }, [currentErrors, removeError]);

  const getBackgroundColor = (type) => {
    switch (type) {
      case 'success':
        return 'bg-green-500';
      case 'info':
        return 'bg-blue-500';
      case 'warning':
        return 'bg-yellow-500';
      case 'error':
      default:
        return 'bg-red-500';
    }
  };

  return (
    <div className="fixed bottom-4 right-4 w-80 space-y-2 z-50">
      {currentErrors.map((error) => (
        <div
          key={error.id}
          className={`p-4 text-white rounded-lg shadow-lg flex justify-between items-center ${getBackgroundColor(error.type)} ${error.removing ? 'opacity-0 transform translate-x-8' : 'opacity-100'} transition-transform duration-300 ease-in-out`}
        >
          <div className="flex-1">
            <span className="block">{error.message}</span>
            {error.details && (
              <ul className="mt-2 text-sm">
                {Object.entries(error.details).map(([field, messages]) => (
                  <li key={field}>
                    <strong>{field}:</strong> {messages.join(', ')}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <button
            onClick={() => {
              setCurrentErrors((prevErrors) =>
                prevErrors.map((e) =>
                  e.id === error.id ? { ...e, removing: true } : e
                )
              );
              setTimeout(() => removeError(error.id), 300); // Remove after animation
            }}
            className="ml-4 text-2xl font-bold"
          >
            &times;
          </button>
        </div>
      ))}
    </div>
  );
};

export default ErrorContainer;
