import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { ErrorContext } from '../ErrorContext';

function AddressSelection({ selectedAddress, setSelectedAddress }) {
  const { user } = useContext(AuthContext);
  const { addError } = useContext(ErrorContext);
  const [addresses, setAddresses] = useState([]);
  const [cities, setCities] = useState([]);
  const [newAddress, setNewAddress] = useState({
    address_line: '',
    city: '',
    state: '',
    country: '',
    postal_code: ''
  });
  const [addingNew, setAddingNew] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAddresses = async () => {
      if (user) {
        try {
          const response = await fetch('https://m0hamady.pythonanywhere.com/api/addresses/', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
          if (!response.ok) {
            throw new Error('Error fetching addresses');
          }
          const data = await response.json();
          setAddresses(data);
        } catch (err) {
          addError({ type: 'error', message: 'Unable to load addresses.' });
          console.error('Error fetching addresses:', err);
        window.location.href = '/login'

        }
      }
    };

    const fetchCities = async () => {
      try {
        const response = await fetch('https://m0hamady.pythonanywhere.com/api/cities/');
        if (!response.ok) {
          throw new Error('Error fetching cities');
        }
        const data = await response.json();
        setCities(data);
      } catch (err) {
        addError({ type: 'error', message: 'Unable to load cities.' });
        console.error('Error fetching cities:', err);
      }
    };

    fetchAddresses();
    fetchCities();
  }, [user, addError]);

  const handleAddNewAddress = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://m0hamady.pythonanywhere.com/api/addresses/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(newAddress)
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessages = {
          message: 'Error adding new address',
          details: errorData.errors || {}
        };
        addError({ type: 'error', ...errorMessages });
        throw new Error('Error adding new address');
      }

      const data = await response.json();
      
      addError({ type: 'success', ...{message:data.message} });
      setAddresses([...addresses, data.data]);
      setAddingNew(false);
      setSelectedAddress(data.data);
    } catch (err) {
      console.error('Error adding new address:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-lg space-y-4 mb-4">
      <h2 className="text-3xl font-bold mb-4 text-gray-800">Select Shipping Address</h2>
      {addresses.length > 0 ? (
        addresses.map((address) => (
          <div key={address.id} className="flex items-center mb-4 p-4 border border-gray-200 rounded-lg shadow-sm">
            <input
              type="radio"
              name="address"
              value={address.id}
              checked={selectedAddress && selectedAddress.id === address.id}
              onChange={() => setSelectedAddress(address)}
              className="mr-4"
            />
            <div>
              <span className="block text-lg font-medium text-gray-700">
                {`${address.address_line}, ${address.city.name}, ${address.state}, ${address.country}, ${address.postal_code}`}
              </span>
            </div>
          </div>
        ))
      ) : (
        <p className="text-gray-600">No addresses available. Please add a new address.</p>
      )}
      <button
        onClick={() => setAddingNew(true)}
        className="w-full py-2 px-4 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none"
      >
        Add New Address
      </button>
      {addingNew && (
        <div className="mt-6 space-y-4">
          <input
            type="text"
            placeholder="Address Line"
            value={newAddress.address_line}
            onChange={(e) => setNewAddress({ ...newAddress, address_line: e.target.value })}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <select
            value={newAddress.city}
            onChange={(e) => setNewAddress({ ...newAddress, city: e.target.value })}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="" disabled>Select City</option>
            {cities.map((city) => (
              <option key={city.id} value={city.id}>{city.name}</option>
            ))}
          </select>
          <input
            type="text"
            placeholder="State"
            value={newAddress.state}
            onChange={(e) => setNewAddress({ ...newAddress, state: e.target.value })}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="text"
            placeholder="Country"
            value={newAddress.country}
            onChange={(e) => setNewAddress({ ...newAddress, country: e.target.value })}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="text"
            placeholder="Postal Code"
            value={newAddress.postal_code}
            onChange={(e) => setNewAddress({ ...newAddress, postal_code: e.target.value })}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={handleAddNewAddress}
            disabled={loading}
            className="w-full py-2 px-4 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none"
          >
            {loading ? 'Adding...' : 'Add Address'}
          </button>
        </div>
      )}
    </div>
  );
}

export default AddressSelection;
