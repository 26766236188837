import React, { useState, useEffect } from "react";
import axios from "axios";

const AddressesComponent = ({ addresses, setAddresses }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [editingAddress, setEditingAddress] = useState(null);
  const [cities, setCities] = useState([]);
  const [formData, setFormData] = useState({
    address_line: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
  });

  const storedToken = localStorage.getItem("token");

  useEffect(() => {
    // Fetch cities when the component mounts
    const fetchCities = async () => {
      try {
        const response = await fetch("https://m0hamady.pythonanywhere.com/api/cities/");
        if (!response.ok) {
          throw new Error("Error fetching cities");
        }
        const data = await response.json();
        setCities(data);
      } catch (err) {
        console.error("Error fetching cities:", err);
      }
    };

    // Fetch addresses when the component mounts
    const fetchAddresses = async () => {
      try {
        const response = await axios.get(
          "https://m0hamady.pythonanywhere.com/api/addresses/",
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        setAddresses(response.data);
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };

    fetchCities();
    fetchAddresses();
  }, [editingAddress]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddClick = () => {
    setIsAdding(true);
    setEditingAddress(null);
    setFormData({
      address_line: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
    });
  };

  const handleEditClick = (address) => {
    setIsAdding(false);
    setEditingAddress(address);
    setFormData({
      address_line: address.address_line,
      city: address.city.id, // Assuming the city has an ID property
      state: address.state,
      postal_code: address.postal_code,
      country: address.country,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        ...formData,
        city: formData.city, // Ensure city contains the city ID
      };
      
      if (editingAddress) {
        // Update existing address
        await axios.put(
          `https://m0hamady.pythonanywhere.com/api/addresses/${editingAddress.id}/`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        setAddresses((prev) =>
          prev.map((addr) =>
            addr.id === editingAddress.id ? { ...addr, ...formData } : addr
          )
        );
      } else {
        // Add new address
        const response = await axios.post(
          "https://m0hamady.pythonanywhere.com/api/addresses/",
          payload,
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        setAddresses((prev) => [...prev, response.data]);
      }
      setIsAdding(false);
      setEditingAddress(null);
      setFormData({
        address_line: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
      });
    } catch (error) {
      console.error("Error submitting address:", error);
    }
  };

  return (
    <div>
      <h2 className="text-gray-600 font-semibold mb-4">Addresses</h2>
      <button
        onClick={handleAddClick}
        className="mb-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
      >
        Add Address
      </button>

      <div className="overflow-x-auto">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Address Line
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                City
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                State
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Postal Code
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Country
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {addresses.map((address) => (
              <tr key={address.id}>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {address.address_line}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {address.city.name}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {address.state}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {address.postal_code}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <p className="text-gray-900 whitespace-no-wrap">
                    {address.country}
                  </p>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <button
                    onClick={() => handleEditClick(address)}
                    className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {(isAdding || editingAddress) && (
        <form
          onSubmit={handleSubmit}
          className="mt-6 bg-white p-4 rounded shadow"
        >
          <h3 className="text-lg font-semibold mb-4">
            {editingAddress ? "Edit Address" : "Add New Address"}
          </h3>
          <label className="block mb-4">
            <span className="text-gray-700">Address Line</span>
            <input
              type="text"
              name="address_line"
              value={formData.address_line}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              required
            />
          </label>
          <label className="block mb-4">
            <span className="text-gray-700">City</span>
            <select
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              required
            >
              <option value="">Select a city</option>
              {cities.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
          </label>
          <label className="block mb-4">
            <span className="text-gray-700">State</span>
            <input
              type="text"
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              required
            />
          </label>
          <label className="block mb-4">
            <span className="text-gray-700">Postal Code</span>
            <input
              type="text"
              name="postal_code"
              value={formData.postal_code}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              required
            />
          </label>
          <label className="block mb-4">
            <span className="text-gray-700">Country</span>
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              required
            />
          </label>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => {
                setIsAdding(false);
                setEditingAddress(null);
              }}
              className="px-4 py-2 bg-gray-400 text-white rounded mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              {editingAddress ? "Update" : "Add"} Address
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddressesComponent;
