import { Swiper, SwiperSlide } from "swiper/react";
import { MdNoDrinks } from "react-icons/md";
import "swiper/css";
import { FaLeaf } from "react-icons/fa";
import { TbTestPipe } from "react-icons/tb";
import Bg from "./C4 (1).png";
import Bg3 from "./C2 (2).png";
import Bg4 from "./C2 (2).png";
import Bg1 from "./08.png";
import Bg2 from "./last_vision_of_studio.png";
import { createWhatsAppLink } from "./WhatsappMessage";

const Slide = ({ title, content, slideNumber, backgroundImageUrl }) => (
  <div
    className="bg-third shadow-md rounded-lg overflow-hidden bg-cover w-[250px] h-[250px] mx-auto"
    style={{ backgroundImage: `url('${backgroundImageUrl}')` }}
  >
    {/* Additional content can be added here */}
  </div>
);

const slideData = [
  {
    title: "SOPHIA",
    content: "Whitening cream",
    slideNumber: 449,
    backgroundImageUrl: Bg1,
  },
  {
    title: "SOPHIA",
    content: "Whitening cream",
    slideNumber: 450,
    backgroundImageUrl: Bg,
  },
  {
    title: "SOPHIA",
    content: "Whitening cream",
    slideNumber: 451,
    backgroundImageUrl: Bg2,
  },
  {
    title: "SOPHIA",
    content: "Whitening cream",
    slideNumber: 452,
    backgroundImageUrl: Bg3,
  },
  {
    title: "SOPHIA",
    content: "Whitening cream",
    slideNumber: 453,
    backgroundImageUrl: Bg4,
  },
  // Add more slide objects as needed
];

export default () => {
  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={1}
      breakpoints={{
        640: {
          slidesPerView: 5,
        },
        768: {
          slidesPerView: 6,
        },
      }}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
      className="h-[395px] justify-center align-middle "
    >
      {slideData.map((slide, index) => (
        <SwiperSlide key={index} className="my-auto  h-[250px!important] w-[250px] rounded-lg mx-4">
          <a
            href={createWhatsAppLink(
              `I would like to ask about ${slide.content} with price 222 from site.`
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Slide {...slide} />
          </a>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
