import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const UserProfile = ({ user }) => {
  if (!user) return null;

  return (
    <div className="max-w-2xl mx-4 sm:max-w-sm md:max-w-sm lg:max-w-sm xl:max-w-sm sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto mt-16 bg-white shadow-xl rounded-lg text-gray-900">
         <div className="flex flex-col items-center py-8">
              <img
                className="h-32 w-32 rounded-full border-4 border-white object-cover"
                src="https://images.unsplash.com/photo-1549880338-65ddcdfd017b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ"
                alt="User Profile"
              />
              <h3 className="text-gray-800 text-3xl font-semibold mt-4">{user && user.username}</h3>
              <p className="text-gray-500 text-sm mt-2">{user && user.location}</p>
              <p className="text-gray-700 text-sm mt-1">{user && user.email}</p>
              <p className="text-gray-700 text-sm mt-1">{user && user.mobile_number}</p>
            </div>
    </div>
  );
};

UserProfile.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    profileImage: PropTypes.string,
    coverImage: PropTypes.string,
    followersCount: PropTypes.number,
    postsCount: PropTypes.number,
    articlesCount: PropTypes.number,
  }),
};

export default UserProfile;
