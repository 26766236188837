import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track if user is logged in

  useEffect(() => {
    // Load user from local storage if available
    const storedToken = localStorage.getItem('token');
   // console.log('isLoggedIn',isLoggedIn);
    if (storedToken) {
      setIsLoggedIn(true); // Set isLoggedIn to true if token is present
      // You may decode the token to get user information if needed
      setUser({ token: storedToken });
    }
  }, []);

  const login = async (username, password) => {
    try {
      const response = await fetch('https://m0hamady.pythonanywhere.com/api/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }), // Send username and password in the body
      });

      if (!response.ok) {
        throw new Error('Login failed');
      }

      const data = await response.json();
      const { token, username: loggedInUsername } = data; // Destructure username from response data

      // Save token to local storage
      localStorage.setItem('token', token);
      // Update user state with token and username
      setUser({ token, username: loggedInUsername, isLoggedIn , setIsLoggedIn });
      return true

    } catch (error) {
      console.error('Login error:', error.message);
      return false
    }
  };

  const logout = () => {
    // Clear user state and local storage
    setUser(null);
    localStorage.removeItem('token');
  };

  return (
    <AuthContext.Provider value={{ user,setUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
