import React from "react";
import { useInView } from "react-intersection-observer";
import ShopByText from "../../utilies/Swippertext";
import ShopProducts from "../../utilies/NewArivals";
import CardCarousel from "../../utilies/Features_swi";
import NewArivals from "../../utilies/NewArivals";
import Results from "../../utilies/Results";
import { Link } from "react-router-dom";
import HeroSection from "./HeroSection";
import { CardCarouselImage, NewArrivalsImage } from "../../assets/images/indedx";

const Home = () => {
  const { ref: section1Ref, inView: section1InView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const { ref: section2Ref, inView: section2InView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const { ref: section3Ref, inView: section3InView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const { ref: section4Ref, inView: section4InView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const { ref: section5Ref, inView: section5InView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });
  const { ref: section6Ref, inView: section6InView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });
  const { ref: section7Ref, inView: section7InView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <div className="bg-secondary h-100 font-lato text-cormorant ">
      {/* Section 1 */}
      {/* <Section1 ref={section1Ref} inView={section1InView} /> */}
      <HeroSection ref={section1Ref} inView={section1InView} />

      {/* Section 2 */}
      <Section2 ref={section2Ref} inView={section2InView} />

      {/* Section 3 */}
      <Section3 ref={section3Ref} inView={section3InView} />

      {/* Section 4 */}
      <Section4 ref={section4Ref} inView={section4InView} />

      {/* Section 5 */}
      <Section5 ref={section5Ref} inView={section5InView} />
      <Section6 ref={section6Ref} inView={section6InView} />
      <Section7 ref={section7Ref} inView={section7InView} />
    </div>
  );
};

const Section1 = React.forwardRef(({ inView }, ref) => (
  <section
    className={`py-20 transition-opacity h-100  p-8 duration-1000 h-[95vh] max-sm:mb-24  ${
      inView ? "opacity-100" : "opacity-0"
    }`}
    ref={ref}
  >
    <div className="grid  grid-cols-2 max-sm:grid-cols-1 lg:grid-cols-2 md:grid-cols-1">
      <div className="container mx-auto px-4 justify-center align-middle flex flex-col">
        <div
          className="relative h-[650px] w-[650px] max-sm:h-80 max-sm:w-[80vw] rounded-full shadow-3xl bg-cover"
          style={{ backgroundImage: `url(${CardCarouselImage})` }}
        >

        </div>
      </div>
      <div className="mx-auto px-4 h-100 justify-center align-middle flex flex-col normal-font text-[120px!important] max-sm:mt-12 lg:w-[350px] ">
        <h1 className="text-4xl font-bold mb-4">
          Cosmetics that every one loves
        </h1>
        <p className=" mb-8 normal-font text-xl">
          Sophia, founded in 2013, has become a trailblazer in the skincare
          industry, revolutionizing beauty routines worldwide.
        </p>
        <a href="#Section4">
          <div className="w-12 h-12 bg-primary rounded-full relative hover:cursor-pointer">
            <div className="w-10 h-10 bg-secondary rounded-full absolute left-1 top-1 group-hover:bg-yellow-400 duration-200"></div>
            <div className="w-8 h-8 bg-primary rounded-full absolute left-2 top-2 flex text-white justify-center align-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 m-auto"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </div>
          </div>
        </a>
      </div>
    </div>
  </section>
));

const Section2 = React.forwardRef(({ inView }, ref) => (
  <section
    className={`py-20 bg-third text-cormorant transition-opacity duration-1000 ${
      inView ? "opacity-100" : "opacity-0"
    }`}
    ref={ref}
  >
    <div className="container mx-auto px-4 flex title-font  flex-col justify-center ">
      <div className=" w-fit m-auto text-center">
        {/* <h2 className="text-3xl font-bold mb-4  text-center ">Section 2</h2> */}
        <p className="text-3xl   text-center  normal-font  uppercase tracking-normal ">
          enjoy with our products.
        </p>
      </div>
      <ShopByText />
      {/* Add more content for section 2 */}
    </div>
  </section>
));
const Section3 = React.forwardRef(({ inView }, ref) => (
  <section
    className={`py-20  text-cormorant transition-opacity duration-1000 max-sm:py-12 ${
      inView ? "opacity-100" : "opacity-0"
    }`}
    ref={ref}
  >
    <div className="container mx-auto px-4 grid  title-font  grid-cols-4  max-sm:grid-cols-1 justify-center lg:grid-cols-4 md:grid-cols-1 ">
      <div className=" w-fit m-auto text-center col-span-1 flex flex-col gap-5">
        {/* <h2 className="text-3xl font-bold mb-4  text-center ">Section 2</h2> */}
        <div>
          <h6 className="   text-center ">New Arrivals.</h6>
          <h1 className="   text-center text-3xl tracking-wide ">
            Every day Essentials.
          </h1>
          <p className="   text-center  normal-font tracking-wide">
            Our core whitening collection features the everyday products you
            need for a consistently radiant, even-toned complexion. From a
            gentle cleansing foam to a nourishing daily moisturizer, these
            essential formulas work together to deliver lasting brightness and
            luminosity. Elevate your skincare routine with these versatile,
            multi-tasking heroes.
          </p>
        </div>
        <Link to={"/products"}>
          <div className="flex  relative">
            <div className="w-12 h-12 bg-primary rounded-full relative hover:cursor-pointer">
              <div className="w-10 h-10 bg-secondary rounded-full absolute left-1 top-1 group-hover:bg-yellow-400 duration-200"></div>
              <div className="w-8 h-8 bg-primary rounded-full absolute left-2 top-2 flex text-white justify-center align-middle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6 m-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                  />
                </svg>
              </div>
            </div>
            <span className="my-auto ml-2 text-xl normal-font">
              preview products
            </span>
          </div>
        </Link>
      </div>
      <div className="col-span-3 h-[500px] max-sm:h-[400px] ">
        <ShopProducts />
      </div>
      {/* Add more content for section 2 */}
    </div>
  </section>
));

const Section4 = React.forwardRef(({ inView }, ref) => (
  <section
    className={`  text-cormorant transition-opacity duration-1000 max-sm:h-[140vh]  bg-third max-sm:p-4 ${
      inView ? "opacity-100" : "opacity-0"
    }`}
    ref={ref}
    id="Section4"
  >
    <div className="container h-[600px] grid  title-font  grid-cols-4 gap-8   max-sm:grid-cols-1 justify-between ">
      <div
        className="col-span-2  h-[100%] max-sm:h-[400px]   bg-cover   "
        accordion
        style={{ backgroundImage: `url(${CardCarouselImage})` }}
      ></div>
      <div className=" w-fit m-auto text-center col-span-2 flex flex-col gap-5 ">
        {/* <h2 className="text-3xl font-bold mb-4  text-center ">Section 2</h2> */}
        <div className="flex flex-col text-left  gap-3">
          <h6 className="   text-left  tracking-normal ">About us</h6>
          <h1 className="   text-left text-[41px] -tracking-normal  ">
            We Are top Certified Organic Cosmetics Products .
          </h1>
          <p className="   text-left  normal-font text-xl tracking-tight">
            Pigmentation-Fighting Power. Sophia Whitening Cream: Transforming
            skin with remarkable results.
          </p>
          {/* add list of 4 items */}
          <ul className="list-none pl-0 grid grid-cols-2 normal-font ">
            <li className="flex items-center mb-2">
              <svg
                className="w-5 h-5  mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              Advanced formulation combat pigmentation.
            </li>
            <li className="flex items-center mb-2">
              <svg
                className="w-5 h-5  mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              Fade discoloration, enhance clarity.
            </li>
            <li className="flex items-center mb-2">
              <svg
                className="w-5 h-5  mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              Transformative, diminish pigmentation.
            </li>
            <li className="flex items-center mb-2">
              <svg
                className="w-5 h-5  mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              Radiant, even-toned complexion
            </li>
          </ul>
        </div>
        <Link to={'/about'} className="flex  relative">
          <div className="w-12 h-12 bg-primary rounded-full relative hover:cursor-pointer">
            <div className="w-10 h-10 bg-secondary rounded-full absolute left-1 top-1 group-hover:bg-yellow-400 duration-200"></div>
            <div className="w-8 h-8 bg-primary rounded-full absolute left-2 top-2 flex  justify-center align-middle">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 m-auto"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </div>
          </div>
          <span className="my-auto ml-2 text-xl normal-font">Know more</span>
        </Link>
      </div>

      {/* Add more content for section 2 */}
    </div>
  </section>
));

const Section5 = React.forwardRef(({ inView }, ref) => (
  <section
    className={`  text-cormorant transition-opacity duration-1000 h-[40vh] max-sm:h-[50vh]   bg-secondary ${
      inView ? "opacity-100" : "opacity-0"
    }`}
    ref={ref}
  >
    <div className=" py-12  grid grid-cols-1">
      <div className="grid grid-cols-1">
        <h4 className="m-auto text-center normal-font">Features</h4>
        <h1 className="m-auto text-[50px] text-center font-medium normal-font ">
          Our Product specialty
        </h1>
        <h4 className="m-auto text-center normal-font text-[15px] mt-0 mb-8 max-w-[600px] max-sm:max-w-[370px] text-slate-800">
          At Sophia Beauty, we believe in the magic of self-care. Our Sophia
          White Cream is like a gentle hug for your skin, leaving it soft,
          radiant, and oh-so-kissable! 🌟✨
        </h4>
      </div>
      <div className="">
        <CardCarousel />
      </div>
    </div>
  </section>
));
const Section6 = React.forwardRef(({ inView }, ref) => (
  <section
    className={`  text-cormorant transition-opacity duration-1000   bg-third mt-[14vh] h-fit ${
      inView ? "opacity-100" : "opacity-0"
    }`}
    ref={ref}
  >
    <div className=" py-12  grid grid-cols-2 max-sm:grid-cols-1 ">
      <div className=" ml-a p-2  ">
        <div className="w-full  mb-11">
          <h4 className="font-normal uppercase"> new arrivals</h4>
          <h4 className="text-3xl title-font uppercase ">
            {" "}
            Introducing Our Latest Innovations
          </h4>
          <p className="normal-font font-normal">
            {" "}
            Discover the newest additions to our premium whitening collection.
            Our research and development team is constantly innovating to bring
            you the most advanced, effective skincare solutions. From targeted
            spot treatments to next-generation brightening serums, explore the
            latest breakthroughs in skin transformation. Stay ahead of the curve
            with these freshly launched, cutting-edge formulas
          </p>
        </div>
        <NewArivals />
      </div>
      <div className="grid grid-cols-1 max-h-[500px]   ">
        <img
          src={NewArrivalsImage}
          className=" object-cover max-h-[590px]   w-fit mx-auto  object-center rounded-2xl "
        />
      </div>
    </div>
  </section>
));
const Section7 = React.forwardRef(({ inView }, ref) => (
  <section
    className={`  text-cormorant transition-opacity duration-1000   bg-secondary mt-[12vh] h-fit ${
      inView ? "opacity-100" : "opacity-0"
    }`}
    ref={ref}
  >
    <div className=" ">
      <div className="">
        <h4 className=" text-center text-3xl title-font uppercase my-4 ">
          {" "}
          Proven Performance - Real User Results
        </h4>
        <h4 className=" text-center  normal-font uppercase max-w-[80vw] mx-auto ">
          {" "}
          Don't just take our word for it - see the real-world results our
          customers have experienced with our advanced whitening cream formula.
          Our rigorous clinical testing and user trials have demonstrated
          measurable improvements in skin tone, clarity, and brightness. Check
          out the before-and-after photos and data summaries below to see the
          transformative power of our product.
        </h4>
      </div>
      <Results />
    </div>
  </section>
));

export default Home;
