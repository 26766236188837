import React, { useEffect, useState } from "react";
import axios from "axios";
import ClientData from "./UserComponent";
import Addresses from "./AddressesComponent"; // Import the new component
import Orders from "./Orders Compnent";

export default function Profile() {
  const [user, setUser] = useState(null);
  const [orders, setOrders] = useState([]);
  const [addresses, setAddresses] = useState([]); // New state for addresses
  const [activeTab, setActiveTab] = useState("clientData");
  const storedToken = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await axios.get('https://m0hamady.pythonanywhere.com/api/users/me/', {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        setUser(userResponse.data);

        const ordersResponse = await axios.get('https://m0hamady.pythonanywhere.com/api/orders/', {
          headers: {
            Authorization: `Bearer ${storedToken}`
          }
        });
        setOrders(ordersResponse.data);

        const addressesResponse = await axios.get('https://m0hamady.pythonanywhere.com/api/addresses/', {
          headers: {
            Authorization: `Bearer ${storedToken}`
          }
        });
        setAddresses(addressesResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [storedToken]);

  return (
    <main className="bg-gray-50">
      {/* Background Image Section */}
      <section className="relative h-80 bg-cover bg-center" style={{ backgroundImage: "url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80')" }}>
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </section>

      {/* Profile Card Section */}
      <section className="relative -mt-40 px-4 py-8">
        <div className="container mx-auto">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden mx-auto max-w-4xl">
            {/* Profile Picture and Info */}
            <div className="p-6">
              {/* Example profile picture and user info */}
              {user && (
                <div className="flex items-center">
                  <img src={user.profile_picture} alt="Profile" className="w-24 h-24 rounded-full object-cover" />
                  <div className="ml-4">
                    <h1 className="text-xl font-semibold">{user.name}</h1>
                    <p className="text-gray-600">{user.email}</p>
                  </div>
                </div>
              )}
            </div>

            {/* Tabs */}
            <div className="border-t border-gray-200">
              <div className="flex space-x-2 border-b border-gray-200">
                <button
                  className={`w-full py-3 text-xs font-bold uppercase ${activeTab === "clientData" ? "text-white bg-blue-600" : "text-blue-600 bg-white"} hover:bg-blue-500 hover:text-white transition duration-300`}
                  onClick={() => setActiveTab("clientData")}
                >
                  Client Data
                </button>
                <button
                  className={`w-full py-3 text-xs font-bold uppercase ${activeTab === "orders" ? "text-white bg-blue-600" : "text-blue-600 bg-white"} hover:bg-blue-500 hover:text-white transition duration-300`}
                  onClick={() => setActiveTab("orders")}
                >
                  Orders
                </button>
                <button
                  className={`w-full py-3 text-xs font-bold uppercase ${activeTab === "addresses" ? "text-white bg-blue-600" : "text-blue-600 bg-white"} hover:bg-blue-500 hover:text-white transition duration-300`}
                  onClick={() => setActiveTab("addresses")}
                >
                  Addresses
                </button>
              </div>
              <div className="p-4">
                {activeTab === "clientData" && <ClientData user={user} />}
                {activeTab === "orders" && <Orders orders={orders} />}
                {activeTab === "addresses" && <Addresses addresses={addresses} setAddresses={setAddresses} />} {/* Pass setAddresses to AddressesComponent */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
