import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ProductContext } from '../ProductContext';
import { AuthContext } from '../AuthContext'; // Import AuthContext for user authentication
import { Logo } from '../assets/images/indedx';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { basket } = useContext(ProductContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { user, setUser } = useContext(AuthContext); // Get user from AuthContext
  
  useEffect(() => {
    // Redirect to login if user is not authenticated
    if (user) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [user]); // Dependency array with user

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    // Clear user authentication state (example: using localStorage)
    setUser(null);
    localStorage.removeItem('token');
    navigate('/login'); // Redirect to login page after logout
  };

  return (
    <nav className="bg-primary text-white font-lato w-[90vw] max-sm:w-[80vw] rounded-2xl title-font fixed mx-12 my-3 top-0 z-50">
      <div className="container mx-auto  flex justify-between px-2 items-center">
        <div className="text-2xl font-bold">
          <Link to="/" className="hover:text-lavender title-font tracking-widest text-[30px] font-thin">
          <img
                src={Logo}
                alt="SOPHIA Logo"
                className="h-24 max-sm:h-16"
              />
          </Link>
        </div>
        <div className="md:flex space-x-6 hidden">
          <Link to="/about" className="hover:text-lavender font-thin tracking-widest text-xl capitalize">About</Link>
          <Link to="/contact" className="hover:text-lavender font-thin tracking-widest text-xl capitalize">Contact</Link>
          <Link to="/products" className="hover:text-lavender font-thin tracking-widest text-xl capitalize">Products</Link>
          <div className="relative flex gap-3 items-center">
            {isLoggedIn ? (
              <>
                <Link
                  to="/orders"
                  className="hover:text-lavender font-thin tracking-widest text-xl capitalize"
                >
                  Orders
                </Link>
                <Link
                  to="/me"
                  className="hover:text-lavender font-thin tracking-widest text-xl capitalize"
                >
                  Profile
                </Link>
                <button
                  onClick={handleLogout}
                  className="block hover:text-lavender font-thin tracking-widest text-xl"
                >
                  Logout
                </button>
              </>
            ) : (
              <Link to="/login" className="block hover:text-lavender font-thin tracking-widest text-xl" onClick={toggleMenu}>
                Login
              </Link>
            )}
            <Link to="/basket" className="hover:text-lavender font-thin tracking-widest text-xl capitalize">
              <svg
                className="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l1.38 6.9c.1.5.5.1H15.1c.4 0 .7-.2.9-.5L17 13H7z" />
              </svg>
              {basket.length > 0 && (
                <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                  {basket.length}
                </span>
              )}
            </Link>
          </div>
        </div>
        <button
          className="block md:hidden focus:outline-none"
          onClick={toggleMenu}
        >
          {basket.length > 0 && (
            <span className="ml-1 inline-flex absolute items-center justify-center px-1 py-1 text-[6px] font-bold leading-none text-red-100 bg-red-600 rounded-full">
              {basket.length}
            </span>
          )}
          <svg
            className="h-6 w-6 fill-current"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 6h16v2H4V6zm0 5h16v2H4v-2zm0 5h16v2H4v-2z"
            />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div
          className={`md:hidden absolute bg-primary w-full px-4 py-2 space-y-2 z-50 rounded-2xl mt-1 animate-water-flow`}
        >
          <Link
            to="/about"
            className="block hover:text-lavender font-thin tracking-widest text-xl"
            onClick={toggleMenu}
          >
            About
          </Link>
          <Link
            to="/contact"
            className="block hover:text-lavender font-thin tracking-widest text-xl"
            onClick={toggleMenu}
          >
            Contact
          </Link>
          <Link
            to="/products"
            className="block hover:text-lavender font-thin tracking-widest text-xl"
            onClick={toggleMenu}
          >
            Products
          </Link>
          <Link
            to="/basket"
            className="block hover:text-lavender font-thin tracking-widest text-xl"
            onClick={toggleMenu}
          >
            Basket
            {basket.length > 0 && (
              <span className="ml-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                {basket.length}
              </span>
            )}
          </Link>
          {isLoggedIn ? (
            <>
              <Link
                to="/orders"
                className="block hover:text-lavender font-thin tracking-widest text-xl"
                onClick={toggleMenu}
              >
                Orders
              </Link>
              <Link
                to="/me"
                className="block hover:text-lavender font-thin tracking-widest text-xl"
                onClick={toggleMenu}
              >
                Profile
              </Link>
              <button
                onClick={handleLogout}
                className="block hover:text-lavender font-thin tracking-widest text-xl"
              >
                Logout
              </button>
            </>
          ) : (
            <Link
              to="/login"
              className="block hover:text-lavender font-thin tracking-widest text-xl"
              onClick={toggleMenu}
            >
              Login
            </Link>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
