import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { Logo } from '../assets/images/indedx';

export default function Footer() {
  return (
    <footer className="bg-third py-8 text-white">
      <div className="mx-auto w-full max-w-screen-xl p-4 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0 flex items-center">
            <a href="https://SOPHIA.com/" className="flex items-center">
              <img
                src={Logo}
                alt="SOPHIA Logo"
                className="h-48"
              />
            </a>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-white uppercase font-lato title-font tracking-wide text-xl">Resources</h2>
              <ul className="text-white dark:text-white font-medium">
                <li className="mb-4">
                  <Link to="/about" className="hover:underline">About</Link>
                </li>
                <li>
                  <Link to="/products" className="hover:underline uppercase">Products</Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-white uppercase font-lato title-font tracking-wide text-xl">Follow Us</h2>
              <div className="flex space-x-4">
                <a href="https://www.facebook.com/SophiaC0smetics" className="text-white hover:text-white dark:hover:text-white">
                  <FaFacebookF className="h-6 w-6 text-white" />
                </a>
                <a href="https://www.instagram.com/SophiaCosmetics" className="text-white hover:text-white dark:hover:text-white">
                  <FaInstagram className="h-6 w-6 text-white" />
                </a>
                <a href="https://twitter.com/SophiaCosmetics" className="text-white hover:text-white dark:hover:text-white">
                  <FaTwitter className="h-6 w-6 text-white" />
                </a>
                <a href="https://www.linkedin.com/company/sophia-cosmetics" className="text-white hover:text-white dark:hover:text-white">
                  <FaLinkedin className="h-6 w-6 text-white" />
                </a>
              </div>
            </div>
            {/* <div>
              <h2 className="mb-6 text-white uppercase font-lato title-font tracking-wide text-xl">Legal</h2>
              <ul className="text-white dark:text-white font-medium">
                <li className="mb-4">
                  <Link to={'/privacy-policy'} className="hover:underline">Privacy Policy</Link>
                </li>
                <li>
                  <Link to={'/terms'} className="hover:underline">Terms & Conditions</Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-white sm:text-center dark:text-white">
            © 2024 <a href="https://SOPHIA.com/" className="hover:underline">SOPHIA</a>. All Rights Reserved.
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0">
            <a href="https://www.sophia.com/tax-info" className="text-white hover:text-white dark:hover:text-white">
              Tax Registration Number: 0011111111111111
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
