import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
export const getCookie = (name) => {
  const cookieValue = document.cookie.match(
    "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
  );
  return cookieValue ? cookieValue.pop() : "";
};
export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem("token"); // Retrieve token from local` storage
        const csrfToken = getCookie("csrftoken");

        const response = await axios.get(
          "https://m0hamady.pythonanywhere.com/api/orders/",
          {
            headers: {
              Authorization: `Bearer ${token}`, // Set Authorization header with token
              "X-CSRFToken": csrfToken, // Set CSRF token in headers
            },
          }
        );

        setOrders(response.data); // Assuming response.data is an array of orders
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch orders");
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const updateQuantity = (orderIndex, itemIndex, newQuantity) => {
    const updatedOrders = [...orders];
    updatedOrders[orderIndex].items[itemIndex].quantity = newQuantity;
    setOrders(updatedOrders);
  };

  const handleDeleteOrder = async (orderId) => {
    try {
      const token = localStorage.getItem("token"); // Retrieve token from local storage
      const csrfToken = getCookie("csrftoken");

      // Ensure both tokens are available before making the request

      // Example: DELETE request to /api/orders/:orderId or similar endpoint
      const response = await axios.delete(
        `https://m0hamady.pythonanywhere.com/api/orders/${orderId}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-CSRFToken": csrfToken, // Include CSRF token in headers
          },
        }
      );

      // Handle success response
      // console.log(response.data);

      // Remove order from local state (assuming success)
      const updatedOrders = orders.filter((order) => order.id !== orderId);
      setOrders(updatedOrders);

      alert(`Order ID: ${orderId} deleted successfully`);
    } catch (error) {
      console.error("Error deleting order:", error);
      alert("Failed to delete order");
    }
  };

  const handleCheckout = async (orderId) => {
    try {
      const token = localStorage.getItem("token"); // Retrieve token from local storage

      const response = await axios.get(
        `https://m0hamady.pythonanywhere.com/api/checkout/?orderId=${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle success response
      if (response.data.status === "success") {
        // Redirect to invoice URL or show success message
        window.location.href = response.data.invoice_url; // Assuming invoice_url is returned from backend
      } else {
        alert("Checkout failed. Please try again.");
      }
    } catch (error) {
      console.error("Error during checkout:", error);
      alert("Failed to initiate checkout");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-semibold text-gray-900 mb-4">Orders</h1>
      {loading ? (
        <div className="text-center">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-500"></div>
          <p className="mt-2 text-gray-700">Loading...</p>
        </div>
      ) : error ? (
        <div className="bg-white p-6 md:mx-auto text-center">
          <svg
            viewBox="0 0 24 24"
            className="text-red-600 w-16 h-16 mx-auto my-6 animate-bounce"
          >
            <path
              fill="currentColor"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm5.658 14.344L12 12l-5.658 4.344a1.01 1.01 0 01-1.465-1.377l6.345-4.835a1.01 1.01 0 011.465 0l6.345 4.835a1.01 1.01 0 01-1.465 1.377z"
            ></path>
          </svg>
          <p className="text-red-500">{error}</p>
          <button
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            onClick={() => window.location.reload()}
          >
            Refresh
          </button>
        </div>
      ) : (
        <div className="flex gap-4 flex-row flex-wrap ">
          {orders.map((order, orderIndex) => (
            <div
              key={order.id}
              className={`p-6 bg-white border relative ${
                order.paid
                  ? "border-green-200 border-8"
                  : "border-yellow-200 border-8"
              }  rounded-lg shadow w-fit`}
            >
              <svg
                className="w-7 h-7 text-gray-500 mb-3"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z"
                />
              </svg>
              <a
                href="#"
                className="block mt-1 text-lg leading-tight font-medium text-black hover:underline"
              >
                رقم الطلب: {order.id}
              </a>
              <p className="mt-2 text-gray-600 max-w-[400px]">
                {!order.paid
                  ? "⏳ طلبك في انتظار الدفع. نحن متحمسون لتقديم تجربة جديدة لك! يرجى إتمام الدفع للاستمرار."
                  : ""}
              </p>
              {/* "📦 Exciting news! Your order is in transit. It'll be with you soon. Hang tight!" */}
              {/* "🎁 Your order is here! Time to unwrap and enjoy. Thank you for shopping with us!" */}
              <p className="mt-2 text-gray-600 max-w-[400px]" dir="rtl">
                {!order.received && order.paid
                  ? "🚚 طلبك في الطريق إليك! نحن في طريقنا إليك. استرخِ واستعد للاستمتاع!"
                  : order.paid &&
                    "🎉 تهانينا! لقد وصل طلبك! استمتع بمنتجك الجديد. نتمنى أن يجلب لك السعادة!"}
              </p>
              <p className="mt-2 text-gray-600">
                Total Amount: EGP {order.total_amount}
              </p>
              <p className="mt-2 text-gray-600">{order.created_at}</p>
              <p className="mt-2 text-gray-500">{order.items.length} Items</p>
              <div className="mt-4 flex gap-4 flex-row flex-wrap">
                {order.items.map((item, itemIndex) => (
                  <div
                    key={item.id}
                    className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
                  >
                    <img
                      className="   rounded-full object-cover min-w-32 min-h-32 w-32 h-32 max-w-32 max-h-32"
                      src={item.product_image}
                      alt={item.product_name}
                    />
                    <div className="flex flex-col justify-between p-4 leading-normal">
                      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        {item.product_name}
                      </h5>
                      {/* <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{item.product_description}</p> */}
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-4 flex justify-between relative">
                <button
                  onClick={() => handleDeleteOrder(order.id)}
                  disabled={order.delivered && order.paid}
                  className="button2 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                >
                  Delete Order
                </button>

                {order.payment_status === "pending" && !order.paid && (
                  <button
                    onClick={() => handleCheckout(order.id)}
                    className="button2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                  >
                    Checkout
                  </button>
                )}
              </div>
              <Link
                to={`/orders/${order.id}`}
                aria-label={`View details of ${order.id}`}
              >
                <div className="absolute -right-0 -bottom-0 w-12 h-12 rounded-tl-full shadow-3xl bg-secondary"></div>
                <FaEye className="absolute right-3 text-primary bottom-3 z-30 shadow-3xl" />
              </Link>
              {order.paid && !order.received && (
                <p className="text-gray-500 mt-4">
                  * You cannot delete this order because it is in its way to you
                  and paid.
                </p>
              )}
              {order.paid && !order.received && (
                <p className="text-gray-500 mt-4">
                  **"مش هتقدر تحذف الطلب ده لأنه في طريقه ليك ومدفوع."**
                </p>
              )}
             
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
