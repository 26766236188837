// LoginPage.js
import React, { useState, useContext } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';

// Custom hook to track previous path
const usePreviousPath = () => {
  const [path, setPath] = useState('/');
  const [previousPath, setPreviousPath] = useState('/');

  const updatePath = (currentPath) => {
    setPreviousPath(path);
    setPath(currentPath);
  };

  return { previousPath, updatePath };
};

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const { previousPath, updatePath } = usePreviousPath();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);  // Reset the error state

    const success = await login(username, password);

    if (success) {
      navigate(previousPath);
    } else {
      setError('Invalid username or password');
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen flex-col justify-center px-6 py-12 lg:px-8 text-gray-100">
      <div className="sm:mx-auto z-30 max-sm:w-full max-sm:max-w-sm bg-primary rounded-lg text-center items-center justify-center flex py-8 w-[26%] text-white -mb-8 shadow-2xl">
        <h2 className="text-center text-2xl font-bold leading-9 w-fit tracking-tight text-gray-100">
          Sign in to your account
        </h2>
      </div>

      <div className="-mt-10 z-10 sm:mx-auto sm:w-full sm:max-w-sm bg-third py-4 px-4 pt-24 rounded">
        <form className="space-y-6" onSubmit={handleLogin}>
          <div>
            <TextField
              id="username"
              label="Username"
              variant="outlined"
              fullWidth
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                {/* Password */}
              </label>
              <div className="text-sm">
                <Link to="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                  Forgot password?
                </Link>
              </div>
            </div>
            <div className="mt-2 relative">
              <TextField
                id="password"
                label="Password"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="text-gray-500"
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  ),
                }}
              />
            </div>
          </div>

          <div className="flex items-center">
            <FormControlLabel
              control={<Checkbox name="remember-me" color="primary" />}
              label="Remember me"
            />
          </div>

          <div>
            <button
              type="submit"
              variant="contained"
              color="primary"
              className='bg-[#542728] w-full py-2 rounded-lg'
              fullWidth
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              {loading ? 'Signing in...' : 'Sign in'}
            </button>
          </div>

          {error && (
            <div className="text-center text-red-500">
              <p>{error}</p>
            </div>
          )}

          <div className="text-center">
            <p className="text-sm text-gray-600">
              Don't have an account?{' '}
              <Link to="/register" className="font-medium text-indigo-600 hover:text-indigo-500">
                Sign up
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
