import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export default function AfterPayment() {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const location = useLocation();
  const { order_id } = useParams(); // Get the order_id from the URL

  // Function to get query parameters
  const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      paymentId: params.get('paymentId'),
      id: params.get('Id')
    };
  };

  useEffect(() => {
    const { paymentId, id } = getQueryParams(location.search);
   // console.log('order_id',order_id);
    if (paymentId && id) {
      axios.get(`https://m0hamady.pythonanywhere.com/api/Orders/payment/status/`, {
        params: {
          paymentId: paymentId,
          id: id,
          order_id: order_id
        }
      })
      .then(response => {
        setPaymentStatus(response.data.status === 'success' ? 'Successful Payment' : 'Unsuccessful Payment');
      })
      .catch(error => {
        setError('Failed to fetch payment status');
      })
      .finally(() => {
        setLoading(false);
      });
    } else {
      setError('Missing paymentId or id in query parameters');
      setLoading(false);
    }
  }, [location.search]);

  return (
    <div className="bg-gray-100 h-screen flex items-center justify-center">
      {loading ? (
        <div className="text-center">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-500"></div>
          <p className="mt-2 text-gray-700">Loading...</p>
        </div>
      ) : error ? (
        <div className="bg-white p-6 md:mx-auto text-center">
          <svg viewBox="0 0 24 24" className="text-red-600 w-16 h-16 mx-auto my-6 animate-bounce">
            <path fill="currentColor" d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm5.658 14.344L12 12l-5.658 4.344a1.01 1.01 0 01-1.465-1.377l6.345-4.835a1.01 1.01 0 011.465 0l6.345 4.835a1.01 1.01 0 01-1.465 1.377z"></path>
          </svg>
          <p className="text-red-500">{error}</p>
          <button 
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            onClick={() => window.location.reload()}
          >
            Refresh
          </button>
        </div>
      ) : paymentStatus === 'Successful Payment' ? (
        <div className="bg-white p-6 md:mx-auto text-center animate-fade-in">
          <svg viewBox="0 0 24 24" className="text-green-600 w-16 h-16 mx-auto my-6 animate-pulse">
            <path fill="currentColor"
                d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
            </path>
          </svg>
          <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">Payment Done!</h3>
          <p className="text-gray-600 my-2">Thank you for completing your secure online payment.</p>
          <p> Have a great day!  </p>
          <div className="py-10 text-center">
              <a href="#" className="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3">
                  GO BACK 
              </a>
          </div>
        </div>
      ) : (
        <div className="bg-white p-6 md:mx-auto text-center animate-fade-in">
          <svg viewBox="0 0 24 24" className="text-red-600 w-16 h-16 mx-auto my-6 animate-bounce">
            <path fill="currentColor" d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"></path>
          </svg>
          <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">Payment Failed</h3>
          <p className="text-gray-600 my-2">Unfortunately, your payment was not successful.</p>
          <p>Please try again later.</p>
          <div className="py-10 text-center">
              <a href="#" className="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3">
                  GO BACK 
              </a>
          </div>
        </div>
      )}
    </div>
  );
}
