import React, { useContext, useState, useEffect, useMemo } from "react";
import { ProductContext } from '../ProductContext';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import shoppingImage from './shopping.png';
import AddressSelection from './AddressSelection';

function Basket() {
  const navigate = useNavigate();
  const { basket, setBasket } = useContext(ProductContext);
  const { user } = useContext(AuthContext);
  const [show, setShow] = useState(true);
  const [shipping, setShipping] = useState(30);
  const [taxes, setTaxes] = useState(35);  // Initial value will be recalculated
  const [total, setTotal] = useState(0);   // New state for total
  const [loading, setLoading] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const storedToken = useMemo(() => localStorage.getItem('token'), []);
  
  useEffect(() => {
    if (selectedAddress) {

      console.log(selectedAddress.city.shipment_fee);
      const shippingFee = parseFloat(selectedAddress.city.shipment_fee);
      setShipping(shippingFee);

    }
  }, [selectedAddress]);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  useEffect(() => {
    if (basket.length === 0) {
      setShipping(0);
      setTaxes(0);
    }
  }, []);
  useEffect(() => {
    if (basket.length === 0) {
      setShipping(0);
      setTaxes(0);
    }
  }, [basket]);

  useEffect(() => {
    const subtotal = basket.reduce((acc, item) => acc + item.effective_price, 0);
    const calculatedTaxes = (subtotal + shipping) * 0.01; // 1% of subtotal + shipping
    setTaxes(calculatedTaxes);
    setTotal(subtotal + shipping + calculatedTaxes); // Update total whenever subtotal, shipping, or taxes change
  }, [basket, shipping, taxes]);
  useEffect(() => {
    const subtotal = basket.reduce((acc, item) => acc + item.effective_price, 0);
    const calculatedTaxes = (subtotal + shipping) * 0.01; // 1% of subtotal + shipping
    setTaxes(calculatedTaxes);
    setTotal(subtotal + shipping + calculatedTaxes); // Update total whenever subtotal, shipping, or taxes change
  }, []);

  const itemCounts = useMemo(() => {
    return basket.reduce((acc, item) => {
      if (acc[item.id]) {
        acc[item.id].count += 1;
      } else {
        acc[item.id] = { ...item, count: 1 };
      }
      return acc;
    }, {});
  }, [basket]);

  const uniqueItems = useMemo(() => Object.values(itemCounts), [itemCounts]);

  const updateItemCount = (id, count) => {
    const updatedBasket = basket.filter(item => item.id !== id)
      .concat(Array(count).fill().map(() => basket.find(item => item.id === id)));
    setBasket(updatedBasket);
  };

  const removeItem = (id) => {
    setBasket(basket.filter(item => item.id !== id));
  };

  const handleCheckout = async () => {
    if (!selectedAddress) {
      alert('Please select a shipping address');
      return;
    }
    setLoading(true);

    try {
      const products = uniqueItems.map(item => item.id);
      const quantities = uniqueItems.map(item => item.count);

      const response = await fetch('https://m0hamady.pythonanywhere.com/api/orders/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedToken}`,
        },
        body: JSON.stringify({
          products,
          quantities,
          shippingAddress: selectedAddress
        }),
      });

      const data = await response.json();
      setLoading(false);

      if (response.ok) {
        try {
          // const paymentUrl = await getPaymentToken(data.amount, data.order_id, data.customer_name);
          window.open(data.url_payment, '_blank'); // Open payment URL in a new tab/window
        } catch (error) {
          console.error('Error during checkout:', error);
          alert('Error processing payment');
        }
      } else {
        alert('Error creating order');
      }
    } catch (error) {
      setLoading(false);
      alert('Error during checkout');
    }
  };

  return (
    <>
      {show && user && (
        <div className="w-full h-full bg-opacity-90 top-0 overflow-y-auto overflow-x-hidden inset-0 z-50">
          <div className="w-full h-full z-10 right-0 overflow-x-hidden transform translate-x-0 transition ease-in-out duration-3000">
            <div className="flex md:flex-row flex-col justify-between max-sm:flex-col-reverse" id="cart">
              <BasketItems
                uniqueItems={uniqueItems}
                updateItemCount={updateItemCount}
                removeItem={removeItem}
                shoppingImage={shoppingImage}
              />
              <SummarySection
                basket={basket}
                shipping={shipping}
                taxes={taxes}
                subtotal={basket.reduce((acc, item) => acc + item.effective_price, 0)}
                total={total}  // Use the total from state
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                handleCheckout={handleCheckout}
                loading={loading}
              />
            </div>
          </div>
        </div>
      )}

      {loading && (
        <LoadingOverlay />
      )}
    </>
  );
}

const BasketItems = ({ uniqueItems, updateItemCount, removeItem, shoppingImage }) => (
  <div className="w-full md:pl-10 pl-4 pr-10 md:pr-4 md:py-12 py-8 overflow-y-auto overflow-x-hidden no-scrollbar h-screen">
    <p className="text-5xl font-black leading-10 text-gray-800 pt-3">Basket</p>
    {uniqueItems.length === 0 ? (
      <Link to='/products' className="text-lg leading-6 text-gray-800 pt-6">
        <div className="button2 mt-8">
          Let's enjoy shopping together >>
        </div>
        <img className="tenor-gif-embed" src={shoppingImage} alt="Shopping" />
      </Link>
    ) : (
      uniqueItems.map((item, index) => (
        <div key={index} className="md:flex items-center mt-14 py-8 border-t border-gray-200">
          <div className="w-1/4">
            <img src={item.image} alt={item.name} className="w-full h-full object-center object-cover" />
          </div>
          <div className="md:pl-3 md:w-3/4">
            <div className="flex items-center justify-between w-full pt-1">
              <p className="text-base font-black leading-none text-gray-800">{item.name}</p>
              <select
                className="py-2 px-1 border border-gray-200 mr-6 focus:outline-none"
                value={item.count}
                onChange={(e) => updateItemCount(item.id, parseInt(e.target.value))}
              >
                {[...Array(10)].map((_, i) => (
                  <option key={i} value={i + 1}>{i + 1}</option>
                ))}
              </select>
            </div>
            <p className="text-xs leading-3 text-gray-600 pt-2">{item.description}</p>
            {item.key_features.map((feature, featureIndex) => (
              <p key={featureIndex} className="text-xs leading-3 text-gray-600">{feature.feature_text}</p>
            ))}
            <div className="flex items-center justify-between pt-5 pr-6">
              <p
                className="text-xs leading-3 underline text-red-500 pl-5 cursor-pointer"
                onClick={() => removeItem(item.id)}
              >
                Remove
              </p>
              <p className="text-base font-black leading-none text-gray-800">{item.effective_price}</p>
            </div>
          </div>
        </div>
      ))
    )}
  </div>
);

const SummarySection = ({ basket, shipping, taxes, subtotal, total, selectedAddress, setSelectedAddress, handleCheckout, loading }) => (
  <div className={`w-1/2 max-sm:w-full bg-lavender rounded-md shadow-xl h-full ${basket.length === 0 ? 'blur-sm h-[80px]' : ''}`}>
    <div className="flex flex-col md:h-screen px-14 py-20 justify-between overflow-y-auto">
      <div>
        <p className="text-4xl font-black leading-9 text-gray-800">Summary</p>
        <div className="flex items-center justify-between pt-16">
          <p className="text-base leading-none text-gray-800">Subtotal</p>
          <p className="text-base leading-none text-gray-800">{subtotal}</p>
        </div>
        {basket.length > 0 && (
          <>
            <div className="flex items-center justify-between pt-5">
              <p className="text-base leading-none text-gray-800">Shipping</p>
              <p className="text-base leading-none text-gray-800">{shipping}</p>
            </div>
            <div className="flex items-center justify-between pt-5">
              <p className="text-base leading-none text-gray-800">Taxes</p>
              <p className="text-base leading-none text-gray-800">{taxes.toFixed(2)}</p>
            </div>
          </>
        )}
      </div>
      <div className="mt-8">
      <AddressSelection selectedAddress={selectedAddress} setSelectedAddress={setSelectedAddress} />
      </div>
      <div className="flex items-center pb-6 justify-between lg:pt-5 pt-20">
        <p className="text-2xl leading-normal text-gray-800">Total</p>
        <p className="text-2xl font-bold leading-normal text-right text-gray-800">{total.toFixed(2)}</p>
      </div>
      <button
        className="text-base leading-none w-full py-5 bg-gray-800 border-gray-800 border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 text-white"
        onClick={handleCheckout}
        disabled={loading}
      >
        {loading ? 'Processing...' : 'Checkout'}
      </button>
    </div>
  </div>
);

const LoadingOverlay = () => (
  <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
    <div className="loader"></div>
    <p className="text-white text-lg mt-4">Processing...</p>
  </div>
);

export default Basket;
