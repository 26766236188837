import React, { createContext, useState, useEffect } from 'react';

const ProductContext = createContext();

const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [basket, setBasket] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetch('https://m0hamady.pythonanywhere.com/api/products/');
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      setProducts(data); // Assuming data is an array of products
    } catch (error) {
      console.error('Error fetching products:', error.message);
    }
  };

  const addToBasket = (product) => {
    setBasket((prevBasket) => [...prevBasket, product]);
  };

  return (
    <ProductContext.Provider value={{ products, basket, setBasket, addToBasket }}>
      {children}
    </ProductContext.Provider>
  );
};

export { ProductProvider, ProductContext };
