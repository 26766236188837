import { Input } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaWhatsapp, FaPhone, FaFacebookF, FaInstagram } from "react-icons/fa";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { phone, message } = formData;

    try {
      const response = await fetch(
        "https://m0hamady.pythonanywhere.com/api/contact-messages/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ phone, message }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send message");
      }

      setFormData({ name: "", phone: "", message: "" });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  useEffect(() => {
    const fadeInElements = document.querySelectorAll(".fade-in");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-fade-in");
          }
        });
      },
      { threshold: 0.5 }
    );

    fadeInElements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Contact Us - Sophia Cosmetics</title>
        <meta
          name="description"
          content="Contact Sophia Cosmetics to inquire about our products, send a message, or connect via phone and social media. Your message will be responded to promptly."
        />
        <meta
          name="keywords"
          content="contact, cosmetics, Sophia Cosmetics, customer service, message, phone"
        />
        <link rel="canonical" href="https://luxury-sophia-cosmetics.com/contact-us" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Contact Us - Sophia Cosmetics" />
        <meta
          property="og:description"
          content="Get in touch with Sophia Cosmetics to inquire about our products or services. We are here to assist you with any queries you might have."
        />
        <meta
          property="og:image"
          content="https://luxury-sophia-cosmetics.com/path-to-your-image.jpg"
        />
        <meta
          property="og:url"
          content="https://luxury-sophia-cosmetics.com/contact-us"
        />
        <meta property="og:type" content="website" />
        <html lang="en" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us - Sophia Cosmetics" />
        <meta
          name="twitter:description"
          content="Reach out to Sophia Cosmetics for any inquiries. We are here to provide support and answer your questions."
        />
        <meta
          name="twitter:image"
          content="https://luxury-sophia-cosmetics.com/path-to-your-image.jpg"
        />
      </Helmet>
      <div className="py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div>
              <h2
                className="text-3xl font-bold text-gray-900 mb-4 fade-in"
                style={{ transitionDelay: "0s" }}
              >
                Get in Touch
              </h2>
              <p
                className="text-gray-600 mb-8 fade-in"
                style={{ transitionDelay: "0.93s" }}
              >
                Have a question or want to learn more about our products? Don't
                hesitate to reach out!
              </p>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="fade-in" style={{ transitionDelay: "0.46s" }}>
                  <div className="fade-in" style={{ transitionDelay: "0.6s" }}>
                    <label
                      htmlFor="name"
                      className="block text-gray-700 font-medium mb-2"
                    >
                      Name
                    </label>
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      fullWidth
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>
                  <div className="fade-in" style={{ transitionDelay: "0.8s" }}>
                    <label
                      htmlFor="phone"
                      className="block text-gray-700 font-medium mb-2"
                    >
                      Phone
                    </label>
                    <Input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      fullWidth
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      required
                    />
                  </div>
                  <div className="fade-in" style={{ transitionDelay: "1s" }}>
                    <label
                      htmlFor="message"
                      className="block text-gray-700 font-medium mb-2"
                    >
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      rows="4"
                      required
                    ></textarea>
                  </div>
                </div>
                <button
                  type="submit"
                  className="button2"
                  style={{ transitionDelay: "0.69s" }}
                >
                  Send Message
                </button>
              </form>
            </div>
            <div className="flex flex-col items-center justify-center">
              <h2
                className="text-3xl font-bold text-gray-900 mb-4 fade-in"
                style={{ transitionDelay: "0.23s" }}
              >
                Contact Us
              </h2>
              <div className="space-y-4">
                <a
                  href="tel:+201097953956"
                  className="flex items-center text-gray-600 hover:text-indigo-500 fade-in"
                  style={{ transitionDelay: "0.46s" }}
                >
                  <FaPhone className="h-6 w-6 mr-2" />
                  +20 109 795 3956
                </a>
                <a
                  href="https://wa.me/+201097953956"
                  className="flex items-center text-gray-600 hover:text-indigo-500 fade-in"
                  style={{ transitionDelay: "0.6s" }}
                >
                  <FaWhatsapp className="h-6 w-6 mr-2" />
                  +20 109 795 3956
                </a>
                <a
                  href="https://www.facebook.com/SophiaC0smetics"
                  className="flex items-center text-gray-600 hover:text-indigo-500 fade-in"
                  style={{ transitionDelay: "0.8s" }}
                >
                  <FaFacebookF className="h-6 w-6 mr-2" />
                  Sophia Cosmetics
                </a>
                <a
                  href="https://www.instagram.com/SophiaCosmetics"
                  className="flex items-center text-gray-600 hover:text-indigo-500 fade-in"
                  style={{ transitionDelay: "1s" }}
                >
                  <FaInstagram className="h-6 w-6 mr-2" />
                  @SophiaCosmetics
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
