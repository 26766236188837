import axios from 'axios';

const trackVisit = async (pageUrl) => {
  try {
    const response = await axios.post('https://m0hamady.pythonanywhere.com/api/visits/', { page_url: pageUrl });
   // console.log('Visit tracked:', response.data);
  } catch (error) {
    console.error('Error tracking visit:', error);
  }
};

export default trackVisit;
