import React from 'react';

const Orders = ({ orders }) => {
  const getStatusLabel = (order) => {
    if (order.delivered && order.received) {
      return { 
        label: "Completed", 
        color: "text-green-900", 
        bgColor: "bg-green-200" 
      };
    }
    if (order.delivered) {
      return { 
        label: "Delivered", 
        color: "text-green-900", 
        bgColor: "bg-green-200" 
      };
    }
    if (order.packaged) {
      return { 
        label: "Packaged", 
        color: "text-yellow-900", 
        bgColor: "bg-yellow-200" 
      };
    }
    if (order.received) {
      return { 
        label: "Received", 
        color: "text-blue-900", 
        bgColor: "bg-blue-200" 
      };
    }
    return { 
      label: "Pending", 
      color: "text-gray-900", 
      bgColor: "bg-gray-200" 
    };
  };

  return (
    <div>
      <h2 className="text-gray-600 font-semibold mb-4">Orders</h2>
      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Number
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Products
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Created At
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  QRT
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => {
                const { label, color, bgColor } = getStatusLabel(order);

                return (
                  <tr key={order.id}>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <div className="flex items-center">
                        <div className="ml-3">
                          <p className="text-gray-900 whitespace-no-wrap">{order.id}</p>
                        </div>
                      </div>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">
                        {order.items.map((item, index) => (
                          <span key={index}>
                            {item.product_name} {index < order.items.length - 1 ? ", " : ""}
                          </span>
                        ))}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{order.created_at}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <p className="text-gray-900 whitespace-no-wrap">{order.total_amount}</p>
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      <span className={`relative inline-block px-3 py-1 font-semibold ${color} leading-tight`}>
                        <span aria-hidden className={`absolute inset-0 ${bgColor} opacity-50 rounded-full`}></span>
                        <span className="relative">{label}</span>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Orders;
