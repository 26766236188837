import React, { useRef, useEffect, useState, useCallback, useContext } from "react";
import image from "./last_vision_of_studio.png";
import { ProductContext } from "../ProductContext";

const ShopByText = () => {
  const scrollContainerRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [scrollDuration, setScrollDuration] = useState(500);
  const [imagePositions, setImagePositions] = useState({});
  const { products } = useContext(ProductContext);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % products.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [products]);

  useEffect(() => {
    const interval = setInterval(() => {
      setImagePositions((prevPositions) => {
        const newPositions = { ...prevPositions };
        products.forEach((product, index) => {
          newPositions[`image-${index}`] = {
            top: `${Math.floor(Math.random() * 41) - 20}px`,
            left: `${Math.floor(Math.random() * 41) - 20}px`,
          };
        });
        return newPositions;
      });
    }, 2000);

    return () => clearInterval(interval);
  }, [products]);

  const scrollToCategory = useCallback(
    (categoryIndex) => {
      if (scrollContainerRef.current) {
        const categoryWidth = 370; // Adjust as per your design
        const scrollPosition = categoryIndex * categoryWidth;

        scrollContainerRef.current.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
          duration: scrollDuration,
        });
      }
    },
    [scrollDuration]
  );

  useEffect(() => {
    scrollToCategory(activeIndex);
  }, [activeIndex, scrollToCategory]);

  return (
    <div className="flex flex-col max-sm:mt-5">
      <div className="mx-auto capitalize title-font mb-6 text-[100px] max-sm:text-[50px] font-thin">
        {/* Shop by category */}
      </div>
      <div
        ref={scrollContainerRef}
        className="scroll-container flex space-x-4 overflow-x-scroll no-scrollbar md:mx-auto w-[60vw] max-w-[99vw] max-sm:w-[400px]"
      >
        {products.map((product, index) => (
          <CategoryCard
            key={product.id}
            category={product}
            isActive={index === activeIndex}
            imagePosition={imagePositions[`image-${index}`]}
          />
        ))}
      </div>
    </div>
  );
};

const CategoryCard = React.memo(({ category, isActive, imagePosition }) => (
  <div
    className={`mx-auto flex justify-center my-auto align-middle text-center flex-col min-w-[350px] w-[450px] max-sm:w-[600px] bg h-[100%] ${
      isActive ? "active" : "border-[#3e8bbb] transition-all scale-100 duration-1000 ease-in-out shadow-sm opacity-50"
    }`}
  >
    <div className="flex flex-col my-auto">
      <div className="text-2xl normal-font capitalize ml-9">{category.name}</div>
      {/* Display other details as needed */}
    </div>
  </div>
));

export default ShopByText;
