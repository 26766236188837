// src/pages/About.js
import React from "react";
import { Helmet } from "react-helmet";
import VantaBackground from "./VantaBackground";
import orangeImage from "../utilies/12.png";
import orangeImage2 from "../utilies/10.png";
import orangeImage3 from "../utilies/08.png";

export default function About() {
  return (
    <div>
      <Helmet>
        <title>About Us - Sophia Cosmetics</title>
        <meta name="description" content="Learn more about Sophia Cosmetics, our mission, and how to get in touch with us. Discover our dedication to effective skincare solutions and high-quality products." />
        <meta name="keywords" content="Sophia Cosmetics, skincare, cosmetics, about us, our mission, contact us" />
        <meta property="og:title" content="About Us - Sophia Cosmetics" />
        <meta property="og:description" content="Learn more about Sophia Cosmetics, our mission, and how to get in touch with us. Discover our dedication to effective skincare solutions and high-quality products." />
        <meta property="og:image" content={orangeImage} />
      </Helmet>
      <VantaBackground>
        <Section
          title="About Us"
          gradient="bg-gradient-to-b from-[#ffffff90] via-[#c5a260] to-[#a98745] -mt-10"
          image={orangeImage}
          imageAlt="Sophia Cosmetics"
          imageClass="lg:w-1/2 lg:h-auto h-80 w-full object-cover object-bottom rounded-r-full"
          textClass="lg:w-1/2 w-full"
        >
          <h1 className="text-white text-4xl text-left title-font uppercase">About Us</h1>
          <p className="text-blackcurrant mt-4 normal-font text-3xl max-w-[840px] text-left">
            Empowering Beauty. Our brand is dedicated to empowering
            individuals through effective skincare solutions. With a focus on
            innovation and quality, Sophia offers safe and transformative
            products that deliver real results. Discover the beauty within and
            experience the confidence that comes from healthy, radiant skin.
          </p>
        </Section>
        <Section
          title="Our Mission"
          gradient="bg-gradient-to-b from-[#a98745] via-[#c5a260] to-[#ffffff90]"
          image={orangeImage2}
          imageAlt="Sophia Cosmetics"
          imageClass="lg:w-1/2 lg:h-auto  w-full object-cover object-bottom rounded-l-full"
          textClass="lg:w-1/2 w-full"
          reverse
        >
          <h1 className="text-white text-4xl text-left title-font uppercase">Our Mission</h1>
          <p className="text-blackcurrant mt-4 normal-font text-3xl max-w-[840px] text-left">
            Our mission is to deliver high-quality products that bring value
            to our customers.
          </p>
        </Section>
        <Section
          title="Contact Us"
          gradient="bg-gradient-to-t from-third to-transparent"
          image={orangeImage3}
          imageAlt="Sophia Cosmetics"
          imageClass="lg:w-1/2 lg:h-auto h-80 w-full object-cover object-bottom rounded-l-full"
          textClass="lg:w-1/2 w-full"
        >
          <h1 className="text-white text-4xl text-left title-font uppercase">Contact Us</h1>
          <p className="text-blackcurrant mt-4 normal-font text-3xl max-w-[840px] text-left">
            We Are top Certified Organic Cosmetics Products.
          </p>
        </Section>
      </VantaBackground>
    </div>
  );
}

function Section({ title, children, gradient, image, imageAlt, imageClass, textClass, reverse }) {
  return (
    <section className={`flex flex-col lg:flex-row ${reverse ? 'lg:flex-row-reverse' : ''} gap-10 px-10 justify-center items-center text-center h-screen w-screen ${gradient}`}>
      <div className={imageClass}>
        <img
          src={image}
          alt={imageAlt}
          className="w-full h-full object-cover max-sm:h-[60vh] "
        />
      </div>
      <div className={textClass}>
        {children}
      </div>
    </section>
  );
}
